import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Content from './content';

const Container = styled(Content)`
  margin: 3rem auto;
`;

const Heading = styled.h1`
  margin: 0;
`;

const LogoLink = styled(Link)`
  left: -57px;
  position: relative;
  text-decoration: none;
`;

const Header = ({ logo, siteTitle }) => (
  <Container as="header">
    <Heading>
      <LogoLink to="/">
        <Img
          critical
          fadeIn={false}
          fixed={logo.childImageSharp.fixed}
        />
      </LogoLink>
    </Heading>
  </Container>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  logo: PropTypes.object,
}

export default Header;
