import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, image, lang, meta, keywords, title, type }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const facebookAppID = data.site.siteMetadata.facebookAppID;
        const metaDescription =
          description || data.site.siteMetadata.description;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              ...facebookAppID
                ? [{
                  name: `fb:app_id`,
                  content: facebookAppID,
                }]
                : [],
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: type,
              },
              ...image
                ? [
                  {
                    property: `og:image`,
                    content: image.src,
                  },
                  {
                    property: `og:image:height`,
                    content: image.height,
                  },
                  {
                    property: `og:image:width`,
                    content: image.width,
                  },
                ]
                : [],
              {
                name: `twitter:card`,
                content: image ? `summary_large_image` : `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  type: `website`,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.object,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        facebookAppID
      }
    }
  }
`
