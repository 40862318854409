import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Content from './content';

const Container = styled(Content)`
  margin: 3rem auto 0;
  display: flex;
`;

const Copyright = styled.small`
  align-self: center;
  color: #CCCCCC;
  font: 0.75rem sans-serif;
  padding-right: 1rem;
`;

const Navigation = styled.nav`
  align-self: center;
  flex: 1;
`;

const NavigationLink = styled(Link)`
  color: #CCCCCC;
  margin-left: 1rem;
  font: 0.75rem sans-serif;
`;

const Footer = ({ mountains }) => (
  <Container as="footer">
    <Navigation>
      <NavigationLink to="privacy-policy">Privacy Policy</NavigationLink>
    </Navigation>

    <Copyright>© {new Date().getFullYear()} Tyler Hunt</Copyright>

    <Img
      critical
      fadeIn={false}
      fixed={mountains.childImageSharp.fixed}
    />
  </Container>
)

Footer.propTypes = {
  mountains: PropTypes.object,
}

export default Footer;
