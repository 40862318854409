import React from 'react';
import Obfuscate from 'react-obfuscate';

import Content from '../components/content';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" keywords={[`toyota`, `4runner`, `overland`]} />

    <Content>
      <h1>Privacy Policy of Mocha Patrol</h1>

      <p>
        At Mocha Patrol, we collect and manage user data according to the
        following Privacy Policy. By using mochapatrol.com (the “Website”), you
        agree to the terms of this Privacy Policy.
      </p>

      <h2>Data Collected</h2>

      <p>
        We collect anonymous data from every visitor of the Website to monitor
        traffic and fix bugs. For example, we collect information like web
        requests, the data sent in response to such requests, the Internet
        Protocol address, the browser type, the browser language, and a
        timestamp for the request.
      </p>

      <p>
        Minors and children should not use Mocha Patrol. By using the Website,
        you represent that you have the legal capacity to enter into a binding
        agreement.
      </p>

      <h2>Use of the Data</h2>

      <p>
        We only use your personal information to provide you the Mocha Patrol
        services or to communicate with you about the services or the Website.
      </p>

      <p>
        We employ industry standard techniques to protect against unauthorized
        access of data about you that we store, including personal information.
      </p>

      <p>
        We do not share personal information you have provided to us without
        your consent, unless:
      </p>

      <ul>
        <li>
          doing so is appropriate to carry out your own request;
        </li>
        <li>
          we believe it’s legally required;
        </li>
        <li>
          we believe it’s needed to detect, prevent, or address fraud,
          security, or technical issues;
        </li>
        <li>
          otherwise protect our property, legal rights, or that of others.
        </li>
      </ul>

      <p>
        Mocha Patrol is operated from the United States. If you are visiting
        the Website from outside the U.S., you agree to any processing of any
        personal information you provide us according to this policy.
      </p>

      <p>
        Mocha Patrol may contact you, by email or other means. For example,
        Mocha Patrol may send you promotional emails relating to Mocha Patrol
        or other third parties Mocha Patrol feels you would be interested in,
        or communicate with you about your use of the Mocha Patrol website.
        Mocha Patrol may also use technology to alert us via a confirmation
        email when you open an email from us. You can modify your email
        notification preferences by clicking the appropriate link included in
        the footer of email notifications. If you do not want to receive email
        from Mocha Patrol, please opt out of receiving emails at the bottom of
        any Mocha Patrol emails or by editing your profile preferences.
      </p>

      <h2>Sharing of Data</h2>

      <p>
        We don’t share your personal information with third parties. Only
        aggregated, anonymized data is periodically transmitted to external
        services to help us improve the Mocha Patrol Website and service. We
        currently use Google Analytics (traffic analysis, SEO optimization),
        and Mailchimp (mailing list management). We listed below what data
        these third parties extract exactly. Feel free to check out their own
        Privacy Policies to find out more.
      </p>

      <ul>
        <li>
          <strong>Google Analytics</strong>: anonymous (ad serving domains,
          browser type, demographics, language settings, page views,
          time/date), pseudonymous (IP address)
        </li>
        <li>
          <strong>Mailchimp</strong>: name and email for mailing list
          subscriptions
        </li>
      </ul>

      <p>
        We also use social buttons provided by services like Twitter and
        Facebook. Your use of these third party services is entirely optional.
        We are not responsible for the privacy policies and/or practices of
        these third party services, and you are responsible for reading and
        understanding those third party services’ privacy policies.
      </p>

      <p>
        We employ and contract with people and other entities that perform
        certain tasks on our behalf and who are under our control (our
        “Agents”). We may need to share personal information with our Agents in
        order to provide products or services to you. Unless we tell you
        differently, our Agents do not have any right to use Personal
        Information or other information we share with them beyond what is
        necessary to assist us. You hereby consent to our sharing of Personal
        Information with our Agents.
      </p>

      <p>
        We may choose to buy or sell assets. In these types of transactions,
        user information is typically one of the transferred business assets.
        Moreover, if we, or substantially all of our assets, were acquired, or
        if we go out of business or enter bankruptcy, user information would be
        one of the assets that is transferred or acquired by a third party. You
        acknowledge that such transfers may occur, and that any acquirer of us
        or our assets may continue to use your personal information as set
        forth in this policy.
      </p>

      <h2>Changes to the Privacy Policy</h2>

      <p>
        We may amend this Privacy Policy from time to time. Use of information
        we collect now is subject to the Privacy Policy in effect at the time
        such information is used. If we make major changes in the way we
        collect or use information, we will notify you by posting an
        announcement on the Website or sending you an email. A user is bound by
        any changes to the Privacy Policy when using the Services after such
        changes have been first posted.
      </p>

      <p>
        Should you have any question or concern, please contact us at
        {' '}
        <Obfuscate
          email="privacy@mochapatrol.com"
          headers={{ subject: 'Privacy Policy' }}
        />.
      </p>
    </Content>
  </Layout>
)

export default PrivacyPolicyPage;
